import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "./../shared/components/layout";
import PageHeader from "../shared/components/page-header";
import SectionItemsList from "./components/section-items-list";
import SectionContact from "../shared/components/section-contact";

const App = (props) => {
  const { pageContext } = props
  const { t } = useTranslation();

  const metas = {
    title: t("seo.app.title"),
    description: t("seo.app.description")
  }

  const itemsApp = t("app.sectionApp.items", { returnObjects: true })
  const imagesApp = t("app.sectionApp.images", { returnObjects: true })
  const appPretitle = t("app.sectionApp.pretitle")
  const appTitle = t("app.sectionApp.title")

  const itemsManager = t("app.sectionManager.items", { returnObjects: true })
  const imagesManager = t("app.sectionManager.images", { returnObjects: true })
  const managerPretitle = t("app.sectionManager.pretitle")
  const managerTitle = t("app.sectionManager.title")


  return (
    <Layout
      metas={metas}
      pageContext={pageContext}
    >
      <PageHeader
        title={t("app.title")}
        description={t("app.description")}
      />
      <SectionItemsList items={itemsApp} images={imagesApp} pretitle={appPretitle} title={appTitle} type="app" />
      <SectionContact />
      <SectionItemsList items={itemsManager} images={imagesManager} pretitle={managerPretitle} title={managerTitle} direction="reverse" type="manager" />

    </Layout>
  );
};

export default App;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
