import React from 'react'
import { useInView } from 'react-intersection-observer';
import DynamicImage from '../../../shared/components/dynamic-image/index.js';
import PreTitle from '../../../shared/components/pre-title/index.js'
import ItemsList from '../list-items/index.js';
import './index.scss'

const SectionItemsList = ({ images, items, pretitle, title, direction = "default", type = "" }) => {

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '-150px 0px',
    });

    return (
        <article ref={ref} className={`section-items-list section-items-list--${direction}${type ? ` section-items-list--${type}` : ''}${inView ? ' in-view' : ''}`}>
            <div className="wrapper-fluid">
                <div className="main-info grid">
                    <header>
                        <PreTitle text={pretitle} />
                        <h2 className="title">{title}</h2>
                    </header>
                    <div className="images-wrapper">
                        <div className="images-mix">
                            {
                                images.map((img, index) => {
                                    return (
                                        <figure key={`app - section - ${img} - ${index} `} className="image">
                                            <DynamicImage imageKey={img} />
                                        </figure>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="items-wrapper">
                        <ul className="items-list">
                            {
                                items.map((item, index) => {
                                    return (
                                        <ItemsList key={`items-list-${type}-${index}`} item={item} />
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    )
}


export default SectionItemsList