import React from 'react'
import { useInView } from 'react-intersection-observer';
import DynamicImage from '../../../shared/components/dynamic-image/index.js';
import './index.scss'

const ItemsList = ({ item, type = "" }) => {

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '-30px 0px',
    });

    return (
        <li ref={ref} className={`list-item${type ? ` list-item--${type}` : ''}${inView ? ' in-view' : ''}`}>
            <DynamicImage imageKey={item.icon} />
            <div>
                <h4 className="title" >{item.title}</h4>
                <p className="description">{item.description}</p>
            </div>
        </li>
    )
}


export default ItemsList